import { getVersion } from '@/utils/version'
import { STORAGE_KEYS } from '@/constants'

getVersion().then(async (version) => {
  const versionLocal = localStorage.getItem(STORAGE_KEYS.VERSION)
  if (versionLocal !== version) {
    const cacheNames = await caches.keys()
    localStorage.setItem(STORAGE_KEYS.VERSION, version)
    await Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)))
    console.log('finish clear cache')
  }
  await import('./index')
})
