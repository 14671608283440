export enum ROUTE_PATH {
  NOT_FOUND = '/not-found',
  ALL = '*',
  LIVE = '/',
  PREVIEW = '/:channelId/:deviceId'
}

export enum LANGUAGE {
  VI = 'vi',
  EN = 'en'
}

export const LOADING_QUERY_SELECTOR = 'body > .native-loading'

export enum STORAGE_KEYS {
  DEVICE_ID = 'deviceId',
  CHANNELID = 'channelId',
  CODE = 'code',
  STATUS = 'status',
  VERSION = 'version'
}

export enum STATUS_CONNECTION {
  CONNECTED = 'Đã kết nối',
  CONNECTING = 'Đang kết nối...',
  WAITING_CHANNEL = 'Đang chờ kết nối với màn hình',
  LOST_CONNECTION = 'Không có kết nối mạng'
}

export enum GLOBAL_CUSTOM_EVENTS {
  DISCONNECT_NETWORK = 'DISCONNECT_NETWORK',
  CONNECT_NETWORK = 'CONNECT_NETWORK'
}

export enum RESOLUTION {
  '4K' = 3840,
  '2K' = 2560,
  '1080p' = 1920,
  '720p' = 1280
}

export enum DAYS_OF_WEEK_VN {
  Su = 'CN',
  Mo = 'Thứ 2',
  Tu = 'Thứ 3',
  We = 'Thứ 4',
  Th = 'Thứ 5',
  Fr = 'Thứ 6',
  Sa = 'Thứ 7'
}
